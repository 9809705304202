import axios from "axios";
import { IUser } from "../Types/user";
import { getUserFromLS } from "../utils/getUserFromLS";

export const getUser = (callback: (response: IUser) => void, errorCallback) => {
    const token = getUserFromLS().access_token

    const URL = `https://intenti-co.services/db-prod/users/me/`;
    return axios(URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (callback != null) {
          callback(response.data as IUser);
        }
      })
      .catch((error) => {
        // catch error
        if (errorCallback != null) {
          errorCallback(error);
        }
      });
  };