export interface IUser {
    username: string;
    role: USER_ROLE;
    name: string | null;
    email: string | null;
    full_name: string;
    linkedin_profile_url: string | null
    disabled: boolean;
    microsoft_id: string | null;
    last_login: Date;
    client_id: string | null;
    uid: string;
    access_token: string | null;
    client: IClient | null;
    auth_method: string | null;
    token_expires_at: Date;
}

export interface IUserCondensed {
    username: string;
    role: USER_ROLE;
    name: string | null;
    email: string | null;
    full_name: string;
    linkedin_profile_url: string | null
    disabled: boolean;
    last_login: Date;
    client_id: string | null;
    uid: string;
}

export interface IClient {
    name: string;
    domain: string;
    address: string | null;
    privacy_policy: string | null;
    client_type_id: number;
    id: number;
    industry_id: number | null;
}

export interface IReqLogin {
    username: string;
    password: string;
    email?: string;
}

export interface DispatchAuth {
    type: string;
    payload?: any;
}

export enum USER_ROLE {
    ADMIN = 'ADMIN',
    USER = 'USER',
    SALES_REP = 'SALES_REP',
    SALES_LEADER = 'SALES_LEADER'
}

export interface IToken {
    access_token: string,
    token_type: string
}
  