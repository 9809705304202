import { useNavigate } from "react-router-dom";
import "../../Css/Error/404.css";
import { PATH } from "../../Constants/paths";

export const NoPerms = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(PATH.Sales_Home);
  };

  return (
    <div className="error_container">
      <div className="error_contentColumn">
      <i className="fa-sharp-duotone fa-solid fa-ban"></i>
        <div className="error_title">Access Denied</div>
        <div className="error_description">
        You do not have permission to view this page.<br/>Please log in or contact your system administrator if you think this is a mistake.
        </div>
        
        <div className="error_footerButton" onClick={goBack}>
          <i className="fa-duotone fa-solid fa-house"></i>
          <div className="error_footerButtonText">Go Home</div>
        </div>
      </div>
    </div>
  );
};