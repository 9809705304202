export const LOGIN_SUCCESS = 'views/auth/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'views/auth/LOGIN_FAILED';
export const USER_LOADED = 'views/auth/USER_LOADED';
export const AUTH_ERROR = 'views/auth/AUTH_ERROR';
export const LOGOUT = 'views/auth/LOGOUT';
export const REGISTER_SUCCESS = 'views/auth/REGISTER_SUCCESS';
export const REGISTER_FAILED = 'views/auth/REGISTER_FAIL';

// New constants for Microsoft SSO
export const MICROSOFT_LOGIN_SUCCESS = 'MICROSOFT_LOGIN_SUCCESS';
export const MICROSOFT_LOGIN_FAILED = 'MICROSOFT_LOGIN_FAILED';
export const AUTH_LOADING = 'AUTH_LOADING';
