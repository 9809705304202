import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { loadUser, logout, handleMicrosoftRedirect } from '../Components/Auth/authThunks';
import { PageRoutes } from '../Routes/index';
import { initializeMsal } from '../Config/msalConfig';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  loadUser,
  logout,
  handleMicrosoftRedirect,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
interface Props extends ConnectedProps<typeof connector> {}

const _App = (props: Props) => {
  useEffect(() => {
    const initApp = async () => {
      try {
        // Initialize MSAL first
        await initializeMsal();
        console.log("MSAL initialized successfully");
        
        // Then handle Microsoft redirect
        props.handleMicrosoftRedirect();
        
        // Check for token in LS
        if (localStorage.user) {
          props.loadUser();
        }
      } catch (error) {
        console.error("Error initializing app:", error);
      }
    };
    
    initApp();
    
    // Log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.user) props.logout();
    });
    
    return () => {
      window.removeEventListener('storage', () => {
        if (!localStorage.user) props.logout();
      });
    };
  }, [props]);
  
  return <PageRoutes />;
};

export const App = connector(_App);