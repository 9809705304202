import { lazy, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { PATH } from "../Constants/paths";
import { PrivateRoute } from "./privateRoute";
import { USER_ROLE } from "../Types/user";

// ---> Auth Pages
const LoginPage = lazy(() => import("../Pages/Auth/LoginPage"));

// ---> Error Pages
const AccessDeniedPage = lazy(() => import("../Pages/Error/AccessDenied"));
const NotFoundPage = lazy(() => import("../Pages/Error/NotFound"));

// ---> Static Pages
const LandingPage = lazy(() => import("../Pages/Static/LandingPage"));

// ---> Account Pages
const UpdateAccountPage = lazy(
  () => import("../Pages/Account/AllAccountsPage")
);
const AccountDetailsPage = lazy(
  () => import("../Pages/Account/AccountDetailsPage")
);

// ---> Industry Pages
const IndustryInsightsPage = lazy(
  () => import("../Pages/Industry/IndustryInsightsPage")
);

// --> Sales Home Pages
const SalesHomePage = lazy(() => import("../Pages/SalesHome/SalesHomePage"));

export const PageRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Auth Page Routes */}
        <Route path={PATH.LOGIN} Component={LoginPage} />

        {/* Static Pages Routes */}
        <Route path={PATH.LANDING} Component={LandingPage} />

        {/* Account Pages Routes */}
        <Route
          path={PATH.ALL_ACCOUNTS}
          element={
            <PrivateRoute
              roles={[
                USER_ROLE.ADMIN,
                USER_ROLE.SALES_LEADER,
                USER_ROLE.USER,
                USER_ROLE.SALES_REP,
              ]}
              component={UpdateAccountPage}
            />
          }
        />

        <Route
          path={PATH.ACCOUNT_DETAILS}
          element={
            <PrivateRoute
              roles={[
                USER_ROLE.ADMIN,
                USER_ROLE.SALES_LEADER,
                USER_ROLE.USER,
                USER_ROLE.SALES_REP,
              ]}
              component={AccountRouteWrapper}
            />
          }
        />

        {/* Industry Insights Routes */}
        <Route
          path={PATH.INDUSTRY_INSIGHTS}
          element={
            <PrivateRoute
              roles={[
                USER_ROLE.ADMIN,
                USER_ROLE.SALES_LEADER,
                USER_ROLE.USER,
                USER_ROLE.SALES_REP,
              ]}
              component={IndustryInsightsPage}
            />
          }
        />

        {/* Error Page Routes */}
        <Route path={PATH.ACCESS_DENIED} Component={AccessDeniedPage} />

        {/* Sales Home Routes */}
        <Route
          path={PATH.Sales_Home}
          element={
            <PrivateRoute
              roles={[
                USER_ROLE.ADMIN,
                USER_ROLE.SALES_LEADER,
                USER_ROLE.USER,
                USER_ROLE.SALES_REP,
              ]}
              component={SalesHomePage}
            />
          }
        />

        {/* Default Not Found Page for Undefined Routes */}
        <Route path="*" Component={NotFoundPage} />
      </Routes>
    </BrowserRouter>
  );
};

const AccountRouteWrapper = () => {
  const { id } = useParams();
  return <AccountDetailsPage id={id} />;
};
