import * as types from "./authConstants"
import { produce } from 'immer';
import { IUser, USER_ROLE } from "../../Types/user";
import { ActionRedux } from "../../Types/actionRedux";

let userType: IUser = {
  uid: "0",
  username: '',
  role: USER_ROLE.USER,
  name: null,
  email: null,
  full_name: "",
  access_token: '',
  client_id: null,
  client: null,
  linkedin_profile_url: null,
  disabled: false,
  last_login: new Date(),
  auth_method: null,
  token_expires_at: new Date(),
  microsoft_id: null
};

const initialState = {
  loading: true,
  isAuthenticated: false,
  token: null,
  user: userType,
  loginFailed: false,
  authMethod: null as string | null,
  errorMessage: ""
};

export const authReducer = (state = initialState, action: ActionRedux) =>
  produce(state, draft => {
    switch (action.type) {
      case types.AUTH_LOADING:
        draft.loading = true;
        break;
      case types.USER_LOADED:
      case types.LOGIN_SUCCESS:
      case types.REGISTER_SUCCESS:
        localStorage.setItem('user', JSON.stringify(action.payload));
        draft.isAuthenticated = true;
        draft.loading = false;
        draft.token = action.payload.access_token;
        draft.user = action.payload;
        draft.authMethod = "traditional";
        draft.loginFailed = false;
        break;
      case types.MICROSOFT_LOGIN_SUCCESS:
        const microsoftUser = {
          ...action.payload,
          auth_method: 'microsoft'
        };
        localStorage.setItem('user', JSON.stringify(microsoftUser));
        draft.isAuthenticated = true;
        draft.loading = false;
        draft.token = action.payload.access_token;
        draft.user = microsoftUser;
        draft.authMethod = 'microsoft';
        draft.loginFailed = false;
        break;
      case types.LOGIN_FAILED:
        draft.loginFailed = true;
        draft.errorMessage = action.payload || "Login failed";
        localStorage.removeItem('user');
        draft.token = null;
        draft.isAuthenticated = false;
        draft.loading = false;
        break;
      case types.MICROSOFT_LOGIN_FAILED:
        draft.loginFailed = true;
        draft.errorMessage = action.payload || "Microsoft login failed";
        localStorage.removeItem('user');
        draft.token = null;
        draft.isAuthenticated = false;
        draft.loading = false;
        break;
      case types.AUTH_ERROR:
      case types.REGISTER_FAILED:
        localStorage.removeItem('user');
        draft.token = null;
        draft.isAuthenticated = false;
        draft.loading = false;
        break;
      case types.LOGOUT:
        localStorage.removeItem('user');
        draft.token = null;
        draft.user = userType;
        draft.isAuthenticated = false;
        draft.loading = false;
        draft.authMethod = null;
        break;
      default:
        return state;
    }
  });
