import { Configuration, LogLevel, PublicClientApplication, AuthenticationScheme } from "@azure/msal-browser";

const getRedirectUri = () => {
  // Check if running locally
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return "http://localhost:3000/login";
  }
  // Production URI
  return "https://intenti.co/login";
};


export const msalConfig: Configuration = {
  auth: {
    clientId: "5a919d41-d719-4f24-ab03-4c5cca543fb4",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: getRedirectUri(),
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
  authenticationScheme: AuthenticationScheme.BEARER
};

// Create the MSAL instance
export const msalInstance = new PublicClientApplication(msalConfig);

// Export an async function to initialize MSAL
export const initializeMsal = async () => {
  await msalInstance.initialize();
  return msalInstance;
};