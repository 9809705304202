import * as types from './authConstants';
import { IUser } from '../../Types/user';

export const loginSuccess = (user: IUser) => ({
  type: types.LOGIN_SUCCESS,
  payload: user
});

export const loginFailed = () => ({
  type: types.LOGIN_FAILED
});

export const userLoaded = (user: IUser) => ({
  type: types.USER_LOADED,
  payload: user
});

export const authError = () => ({
  type: types.AUTH_ERROR
});

export const logoutSuccess = () => ({
  type: types.LOGOUT
});

export const registerSuccess = (payload: IUser) => ({
  type: types.REGISTER_SUCCESS,
  payload,
});

export const registerFailed = () => ({
  type: types.REGISTER_FAILED,
});

// Microsoft SSO
export const microsoftLoginSuccess = (user: IUser) => (dispatch) => {
  // First dispatch the action
  dispatch({
    type: types.MICROSOFT_LOGIN_SUCCESS,
    payload: user
  });
  
  // Then redirect to home
  window.location.href = '/sales-home';
};

export const microsoftLoginFailed = (errorMessage?: string) => ({
  type: types.MICROSOFT_LOGIN_FAILED,
  payload: errorMessage || "Microsoft login failed"
});

export const setAuthLoading = () => ({
  type: types.AUTH_LOADING
});

export const clearAuthError = () => ({
  type: 'CLEAR_AUTH_ERROR'
});