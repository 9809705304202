import axios from 'axios';
import { IUser } from '../Types/user';

export const validateMicrosoftToken = (
  id_token: string,
  callback: (user: IUser) => void,
  errorCallback: (error: any) => void
) => {
  const URL = `https://intenti-co.services/db-prod/auth/microsoft/validate/`;
  
  return axios.post(URL, { id_token })
    .then((response) => {
      if (callback) {
        callback(response.data as IUser);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
};