import * as actions from './authActions';
import { Dispatch } from 'redux';
import { getUser } from '../../Api/getUser';
import { getUserFromLS } from '../../utils/getUserFromLS';
import { loginRequest, msalInstance, initializeMsal } from '../../Config/msalConfig';
import { AuthenticationResult } from '@azure/msal-browser';
import { validateMicrosoftToken } from '../../Api/validateMicrosoftToken';
// Load user from local storage
export const loadUser = () => async (dispatch: Dispatch<any>) => {
  const user = getUserFromLS();

  if (!user.uid) {
    dispatch(actions.authError());
    return;
  }
  
  try {
    getUser(
      (res) => {
        dispatch(actions.userLoaded(res));
      },
      (err) => {
        dispatch(actions.authError());
      }
    );
  } catch (error) {
    dispatch(actions.authError());
    return;
  }
};

// Ensure MSAL is initialized
let msalInitialized = false;
const ensureMsalInitialized = async () => {
  if (!msalInitialized) {
    await initializeMsal();
    msalInitialized = true;
  }
};

// Microsoft SSO login - Redirect method
export const loginWithMicrosoftRedirect = () => async (dispatch: Dispatch<any>) => {
  dispatch(actions.setAuthLoading());
  
  try {
    await ensureMsalInitialized();
    await msalInstance.loginRedirect(loginRequest);
  } catch (error) {
    console.error('Microsoft redirect failed:', error);
    dispatch(actions.microsoftLoginFailed("Failed to initiate Microsoft login"));
  }
};

// Add a flag to track if we're already processing a redirect
let isProcessingRedirect = false;

// Handle Microsoft redirect
export const handleMicrosoftRedirect = () => async (dispatch: Dispatch<any>) => {
  try {
    // If already processing a redirect, return early
    if (isProcessingRedirect) {
      console.log("Already processing a redirect, skipping duplicate call");
      return;
    }
    
    // Ensure MSAL is initialized
    await ensureMsalInitialized();
    
    // Handle the redirect response
    const response = await msalInstance.handleRedirectPromise();
    
    if (response) {
      // Set flag to true
      isProcessingRedirect = true;
      dispatch(actions.setAuthLoading());
      
      console.log("Microsoft redirect response received:", response);
      
      // Validate the token with your backend
      validateMicrosoftToken(
        response.idToken,
        (userData) => {
          console.log("Microsoft user validated:", userData);
          // Success - dispatch action with user data
          dispatch(actions.microsoftLoginSuccess(userData));
          
          // Reset flag after successful validation
          isProcessingRedirect = false;
          
          // Navigate to home page
          window.location.href = '/sales-home';
        },
        (error) => {
          console.error('Microsoft token validation failed:', error);
          let errorMessage = "Microsoft login failed";
          const message = error.response?.data["detail"];
          
          // Extract more specific error if available
          if (message) {
            errorMessage = message;
          }
          
          dispatch(actions.microsoftLoginFailed(errorMessage));
          // Reset flag after failed validation
          isProcessingRedirect = false;
        }
      );
    }
  } catch (error) {
    console.error('Error handling Microsoft redirect:', error);
    let errorMessage = "Error during Microsoft authentication";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    dispatch(actions.microsoftLoginFailed(errorMessage));
    // Reset flag after error
    isProcessingRedirect = false;
  }
};

// Logout - handles both traditional and Microsoft logout
export const logout = () => async (dispatch: Dispatch<any>) => {
  const user = getUserFromLS();
  
  try {
    // Check if user was authenticated with Microsoft
    if (user.auth_method === 'microsoft') {
      // Ensure MSAL is initialized
      await ensureMsalInitialized();
      
      // Get all accounts
      const accounts = msalInstance.getAllAccounts();
      
      if (accounts.length > 0) {
        // Logout from Microsoft
        msalInstance.logoutRedirect({
          account: accounts[0],
          postLogoutRedirectUri: window.location.origin,
        });
      }
    }
  } catch (error) {
    console.error('Error during logout:', error);
  }
  
  // Always dispatch logout action
  dispatch(actions.logoutSuccess());
};
